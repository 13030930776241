<template>
  <footer class="footer">
    <div class="row m-0 py-5">
      <div class="col-12 col-md-2">
        <div class="footer-logo">
          <img src="../assets/img/logo-white.svg" width="150" alt="" />
          <div>
            <span
              >ISO/IEC 17043 Accredited <br />
              Proficiency Testing Provider</span
            >
          </div>
        </div>
      </div>
      <div class="col-12 col-md-10 footer-links">
        <div>
          <h4>EQA</h4>
          <span
            ><a target="_blank" href="https://eqa.huqas.org/"
              >Quarterly EQA</a
            ></span
          >
          <span
            ><a target="_blank" href="https://monthly-eqa.huqas.org/"
              >Monthly EQA</a
            ></span
          >
        </div>

        <div>
          <h4>Programs</h4>
          <span><a href="/programs#calendar">Categories</a></span>
          <span><a href="/programs">Program Menu</a></span>
          <span><a href="/programs">Request Quote</a></span>
        </div>

        <div>
          <h4>Downloads</h4>
          <span
            ><a
              target="_blank"
              href="https://huqasstrapimedia.s3.us-east-2.amazonaws.com/ISO+certificate.pdf"
              >ISO/IEC 17043:2010 <br />
              Certificate</a
            ></span
          >
          <span
            ><a
              target="_blank"
              href="https://drive.google.com/file/d/1pstZNJG9w6ae1hl38zZkC50fu2zicQ2-/view?usp=drive_link"
              >Catalogue </a
            ></span
          >
          <span
            ><a
              target="_blank"
              href="https://drive.google.com/file/d/1Nb-XwPLG6pP0ahCg1nUXjVC3pY8GMXMV/view?usp=drive_link"
              >EQA Calendar</a
            ></span
          >
          <span
            ><a
              target="_blank"
              href="https://drive.google.com/file/d/1fZKdMCKCdhDOFqZcC77252fOnRN8Ny3M/view?usp=sharing"
              > Performance Evaluation<br /> 
              Criteria</a
            ></span
          >
        </div>
        
        <div>
          <h4>Training</h4>
          <span><a href="/training">2024 Calendar</a></span>
          <span><a href="/training">Register</a></span>
        </div>

        <div>
          <h4>Mentorship</h4>
          <span><a href="/mentorship/quote">Request Quote</a></span>
        </div>

        <div>
          <h4>Conference</h4>
          <span><a href="/conference">Register</a></span>
          <span><a href="/conference">Abstracts</a></span>
          <span><a href="/conference">Booklets</a></span>
        </div>

        



        <div>
          <h4>Newsletter</h4>
          <span @click="showSubscribeForm"><a>Subscribe</a></span>
        </div>
      </div>
    </div>
    <div class="bottombar">
      <span class="slogan">
        Copyright © {{ getYear }}. All rights reserved.
      </span>

      <span class="slogan">
        "........................ advancing healthcare through improved
        laboratory testing"
      </span>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    getYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
